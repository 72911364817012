import React from "react";
import { Box, Stack, Tooltip } from "@twilio-paste/core";
import { Button } from "@twilio-paste/button";
import { PlusIcon } from "@twilio-paste/icons/esm/PlusIcon";
import { useTheme } from "@twilio-paste/theme";
import { ReduxParticipant } from "../../store/reducers/participantsReducer";
import styles from "../../styles";
import AvatarGroup from "../AvatarGroup";
import AccessContext from "../../context/AccessContext";

const DEFAULT_MAX_DISPLAYED_PARTICIPANTS = 5;
const MAX_HIDDEN_PARTICIPANTS = 50;

interface ParticipantsViewProps {
  participants: ReduxParticipant[];
  onParticipantListOpen: () => void;
  maxDisplayedParticipants?: number;
}

interface UserContact {
  id: string;
  userName: string;
  userEmailId: string;
  userJobRole: string;
  userDepartment: string;
  parentId: number;
}

interface TreeNode {
  department: string;
  userContactList: UserContact[];
  children: TreeNode[];
}

const findUserByEmail = (
  tree: TreeNode | null,
  email: string
): string | null => {
  if (!tree || !tree.userContactList) return null;

  // Check the current level of the tree
  const user = tree.userContactList.find((user) => user.userEmailId === email);
  if (user) {
    return user.userName; // Found the user at the current level
  }

  // If not found at the current level, recursively search in children
  for (const child of tree.children) {
    const result = findUserByEmail(child, email);
    if (result) {
      return result; // Found in the children
    }
  }

  return null; // Not found in the current branch
};

function fetchName(
  participant: ReduxParticipant,
  orgChart: TreeNode | null
): string {
  let name: string | null = participant.identity ?? "unknown";
  if (participant.attributes != null) {
    // const friendlyName: string | null =
    //   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //   // @ts-ignore
    //   participant.attributes["friendlyName"];
    const friendlyName: string | null = findUserByEmail(orgChart, name);

    if (friendlyName != null) {
      name = friendlyName;
    } else {
      name = '';
    }
  }
  return name;
}

const ParticipantsView: React.FC<ParticipantsViewProps> = ({
  participants,
  onParticipantListOpen,
  maxDisplayedParticipants = DEFAULT_MAX_DISPLAYED_PARTICIPANTS,
}: ParticipantsViewProps) => {
  const theme = useTheme();
  const { orgChart } = React.useContext(AccessContext);
  // const displayedParticipants = participants.slice(0, maxDisplayedParticipants).map(fetchName);
  // const hiddenParticipants = participants.slice(maxDisplayedParticipants).map(fetchName);
  const displayedParticipants = participants
    .slice(0, maxDisplayedParticipants)
    .map((participant) => fetchName(participant, orgChart[0]));

  // console.log("name: ", displayedParticipants);

  const hiddenParticipants = participants
    .slice(maxDisplayedParticipants)
    .map((participant) => fetchName(participant, orgChart[0]));

  return (
    <Stack
      orientation={["vertical", "horizontal", "horizontal"]}
      spacing="space30"
    >
      <Button variant={"reset"} onClick={onParticipantListOpen}>
        <AvatarGroup names={displayedParticipants} />
      </Button>
      {hiddenParticipants.length > 0 ? (
        <Tooltip
          text={hiddenParticipants.join(", ")}
          placement={"bottom-start"}
        >
          <span
            style={{
              verticalAlign: "top",
              paddingRight: 10,
              color: theme.textColors.colorText,
              fontWeight: theme.fontWeights.fontWeightSemibold,
            }}
          >
            and {hiddenParticipants.length} other Participants
          </span>
        </Tooltip>
      ) : null}
    </Stack>
  );
};

export default ParticipantsView;

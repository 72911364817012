import React, { useState, useEffect, useRef, useContext } from "react";
import Snackbar from "@mui/material/Snackbar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Stack from "@mui/material/Stack";
import LinearProgress from "@mui/material/LinearProgress";
import ringtone from "../../assets/audio/classic-ring-telefone.mp3";
import { MessageContext } from "../../context/MessageContext";
import { firestore } from "../../firebase";
import { sendMessage } from "../../firebase-config";
import AppReadyContext from "../../context/AppReadyContext";

const Toaster = () => {
  const [open, setOpen] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isAccepted, setIsAccepted] = useState(false); // Track acceptance state
  const audioRef = useRef(null);
  const {
    message,
    setMessage,
    accepted,
    setAccepted,
    setMenuOpen,
    setIsRinging,
    setIsCalling,
    setIsCallingType, callSoundOn
  } = useContext(MessageContext);
  const { appReady } = useContext(AppReadyContext);
  const user = localStorage.getItem("user");
  let ringDuration = 90000;

  useEffect(() => {
    console.log("Toaster received message");
    if (
      message.notificationId &&
      (message.type === "Call" || message.type == "call") &&
      message.fromEmail != localStorage.getItem("user")
    ) {
      ringDuration = 90000;
      handleClick2();
    } else if (
      message?.IsSeen === false &&
      message?.sentBy !== localStorage.getItem("user")
    ) {
      // console.log(message);
      if (message.Type === "Call") {
        handleClick();
      }
    } else if (
      message?.Seen === false &&
      message?.SentBy !== localStorage.getItem("user")
    ) {
      // console.log(message);
      if (message.Type === "Call") {
        handleClick();
      }
    }
  }, [message, appReady]);

  const handleClick = () => {
    setOpen(true);
    setProgress(0);
    setIsAccepted(false); // Reset acceptance state
    message.seen = true;
    // handleAccept();
    // setMessage(message);
  };

  const handleClick2 = () => {
    // setOpen(true);
    setProgress(0);
    setIsAccepted(false); // Reset acceptance state
    message.seen = true;
    handleAccept();
    return;
  };

  const handleClose = async (event, reason) => {
    setTimeout(async () => {
      if (!accepted) {
        if (isAccepted == false || null) {
          if (reason === "clickaway" || (reason === "timeout" && isAccepted)) {
            return;
          }
          message.status = "ignored";
          setMessage({});
          localStorage.setItem("accepted", false);
          await sendMessage(
            {
              text: "Ignore",
              sentBy: localStorage.getItem("user"),
              sentTo: [message.SentBy],
              type: "Ignore",
              room: message.Room,
              conversationSid: message.ConversationSid? message.ConversationSid : "",
              names: [],
              from: localStorage.getItem("user"),
              comment: `Call rejected - ${localStorage.getItem("userName")}`,
              seen: false,
              status: "ignored",
            },
            [message.SentBy],
            "Ignore"
          );
          setOpen(false);
          setIsAccepted(false);
        }
      }
    }, 1000);
  };

  const handleAccept = async () => {
    localStorage.setItem("accepted", true);
    message.status = "accepted";
    // setMessage(message);
    setAccepted(true);
    setIsAccepted(true); // Set acceptance state
    setMenuOpen(true);
    setOpen(false); // Manually close the Snackbar to prevent auto hide
    if (audioRef.current) {
      audioRef.current.pause();
    }

    await sendMessage(
      {
        text: "Accept",
        sentBy: localStorage.getItem("user"),
        sentTo: [message.SentBy],
        type: "Accept",
        room: message.Room,
        conversationSid: message.ConversationSid? message.ConversationSid: "",
        names: [],
        from: localStorage.getItem("user"),
        comment: `Call Accepted by ${localStorage.getItem("userName")}`,
        seen: false,
        status: "accepted",
      },
      [message.SentBy],
      "Accept"
    );

    setIsCallingType("Joining");
  };

  const handleReject = async () => {
    console.log("Rejecting", message);
    localStorage.setItem("accepted", false);
    message.status = "rejected";
    console.log("message while reject: ", message);

    if (message.Names.length < 2) {
      await sendMessage(
        {
          text: "Reject",
          sentBy: localStorage.getItem("user"),
          sentTo: [message.SentBy],
          type: "Reject",
          room: message.Room,
          conversationSid: message.ConversationSid? message.ConversationSid : "",
          names: [],
          from: localStorage.getItem("user"),
          comment: `Call rejected by ${localStorage.getItem("userName")}`,
          seen: false,
          status: "rejected",
        },
        [message.SentBy],
        "Reject"
      );
    } else {
      console.log("group call: not rejecting");
    }

    setMessage({});
    setAccepted(false);
    setIsAccepted(true); // Reset acceptance state
    setOpen(false); // Manually close the Snackbar to prevent auto hide
    setIsCallingType(false);
  };

  useEffect(() => {
    if (localStorage.getItem("incomingCallId")) {
      setOpen(false);
    } else if (!accepted) {
      // console.log(accepted)
      let timer;
      if (open) {
        timer = setInterval(() => {
          setProgress((prev) => {
            if (prev >= 100) {
              clearInterval(timer);
              setOpen(false);
              return 100;
            }
            return prev + 0.333; // increment the progress by approximately 1/300 of 100 every 100ms
          });
        }, 100);

        // Play the sound continuously when the snackbar is shown
        if (callSoundOn && audioRef.current) {
          audioRef.current.loop = true; // Set the audio to loop
          audioRef.current
            .play()
            .catch((error) => console.error("Error playing audio:", error));
        }
      }

      return () => {
        clearInterval(timer);
        // Stop the audio when the snackbar is closed
        if (audioRef.current) {
          audioRef.current.pause();
          audioRef.current.currentTime = 0; // Reset the audio to the beginning
        }
      };
    } else if (accepted) {
      setOpen(false);
    }
  }, [open, accepted, callSoundOn]);

  const updateStatus = (message) => {
    if (message) {
      const messageRef = firestore
        .collection(`users/${user}/messages`)
        .doc(message.id);
      messageRef
        .update(message)
        .catch((error) =>
          console.error("Error updating message in Firestore:", error)
        );
    }
  };

  return (
    <Stack spacing={2} sx={{ maxWidth: 600 }}>
      <audio ref={audioRef} src={ringtone} preload="auto" />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={isAccepted ? null : ringDuration}
        onClose={handleClose}
        message={
          <>
            Incoming Call from {message.From || message.from}
            <LinearProgress
              variant="determinate"
              value={progress}
              sx={{ marginTop: 1 }}
            />
          </>
        }
        action={
          <>
            <Button color="secondary" size="small" onClick={handleAccept}>
              Accept
            </Button>
            <Button color="primary" size="small" onClick={handleReject}>
              Reject
            </Button>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
    </Stack>
  );
};

export default Toaster;

import React, { useContext, useState } from 'react'
import PopupLayout from '../UI/Popup/PopupLayout'
import TreeRuleContainer from '../UserManagement/TreeView/TreeRuleContainer'
import ThemeBtn from '../UI/ThemeBtn'
import RequiredAstrict from '../UI/RequiredAstrict'
import { Col } from 'react-bootstrap'
import AccessContext from '../../context/AccessContext'
import { useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../store'
import { successNotification, unexpectedErrorNotification } from '../../helpers'
import { CreateRuleSet } from '../../axios/apis'

const AddRuleSet = ({ open, onClose, title, btn, data, profileList, fetchDetails }) => {

    const { orgzName, selectedDepartments, selectedUsers, } = useContext(AccessContext);

    const dispatch = useDispatch();
    const { addNotifications } = bindActionCreators(actionCreators, dispatch);

    const [submitloader, setSubmitLoader] = useState(false)
    const [ruleName, setRuleName] = useState("")
    const [isConfirm, setIsConfirm] = useState(false)
    const [isCallingDisable, setIsCallingDisable] = useState(false)

    const handleConfirm = () => {
        if (selectedDepartments.length === 0 && selectedUsers.length === 0) {
            unexpectedErrorNotification("At Least select 1 Group or User", addNotifications);
        } else {
            setIsConfirm(true)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setSubmitLoader(true)
        const payloadRule = {
            customer: orgzName.organizationName,
            ruleSetName: ruleName,
            departmentIdList: selectedDepartments,
            userIdList: selectedUsers,
            isCallingDisabled: isCallingDisable
        }
        try {
            const resRule = await CreateRuleSet(payloadRule)
            if (resRule.status === 200) {
                successNotification({
                    message: "Rule Created Successfully",
                    addNotifications,
                });
                setSubmitLoader(false)
                onClose()
                fetchDetails("")
            }
        } catch (error) {
            unexpectedErrorNotification(error.response?.data || "An error occurred while Adding the Rule", addNotifications);
            setSubmitLoader(false)
        }
    }

    const handleCalling = (e) => {
        setIsCallingDisable(!isCallingDisable)
    }


    return (
        <PopupLayout open={open} onClose={onClose} title={title} handleSubmit={handleSubmit}>
            {
                isConfirm ? <>
                    <Col lg={12}>

                        <div className="input-box">
                            <label className='input-label' htmlFor="">Rule Name <RequiredAstrict /></label>
                            <div className="input-field">
                                <input type="text" name="userName" value={ruleName} onChange={(e) => setRuleName(e.target.value)} required />
                            </div>
                        </div>
                    </Col>
                    <div className='d-flex justify-content-end gap-4'>
                        <button className='cancel-btn' onClick={onClose}>Cancel</button>
                        <ThemeBtn name={btn} loader={submitloader} type="submit" />
                    </div>
                </> : <TreeRuleContainer orgName={orgzName.organizationName} chart={orgzName.orgChart} isCallingDisable={isCallingDisable} setIsCallingDisable={setIsCallingDisable}>

                    <div className="action-btn">
                        <button type="button" onClick={onClose} className="reset-btn">
                            Close
                        </button>
                        <ThemeBtn name="Submit" type="button" handleClick={handleConfirm} />
                    </div>
                </TreeRuleContainer>
            }


        </PopupLayout>
    )
}

export default AddRuleSet
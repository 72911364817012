import React, { useState, useEffect, useRef, useContext } from "react";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Stack from "@mui/material/Stack";
import ringtone from "../../assets/audio/achive-sound.mp3";
import { MessageContext } from "../../context/MessageContext";
import AppReadyContext from "../../context/AppReadyContext";
import { actionCreators } from "../../store";
import { bindActionCreators } from "redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const MessageNotification = () => {
  const [open, setOpen] = useState(false);
  const [progress, setProgress] = useState(0);
  const audioRef = useRef(null);
  const [msg, setMsg] = useState(null);
  const {
    message,
    setMessage,
    incomingMessage,
    setIncomingMessage,
    currentConvo,
    soundOn,
  } = useContext(MessageContext);
  const { appReady } = useContext(AppReadyContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { updateCurrentConversation } = bindActionCreators(
    actionCreators,
    dispatch
  );
  const [audioEnabled, setAudioEnabled] = useState(false);

  useEffect(() => {
    if (incomingMessage) {
      console.log("Sound: ", soundOn);
      console.log("Message received: ", incomingMessage);
      setMsg("New Message");
      if (message?.Room !== currentConvo) {
        handleClick();
        setIncomingMessage(false);
      }
    }
  }, [incomingMessage, appReady, currentConvo]);

  const handleClick = () => {
    setOpen(true);
    setProgress(0);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setIncomingMessage(false);
    setOpen(false);
  };

  const onNotificationClick = () => {
    // console.log("Notification clicked!", location.pathname);
    if (location.pathname === "/") {
      updateCurrentConversation(message?.Room);
    } else {
      // console.log("in other page");
      navigate(`/`);
      updateCurrentConversation(message?.Room);
    }
  };
  const handleEnableAudio = () => {
    setAudioEnabled(true);
  };

  useEffect(() => {
    let timer;
    if (open) {
      timer = setInterval(() => {
        setProgress((prev) => {
          if (prev >= 100) {
            clearInterval(timer);
            setOpen(false);
            return 100;
          }
          return prev + 0.0333; // increment the progress by approximately 1/300 of 100 every 100ms
        });
      }, 100);

      // Play audio only if soundOn is true
      if (soundOn && audioRef.current) {
        audioRef.current.loop = false; // Set the audio not to loop
        audioRef.current
          .play()
          .catch((error) => console.error("Error playing audio:", error));
      }
    }

    return () => {
      clearInterval(timer);
      // Stop the audio when the snackbar is closed
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0; // Reset the audio to the beginning
      }
    };
  }, [open, appReady, soundOn]);

  return (
    <div>
      {!audioEnabled && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
          onClick={handleEnableAudio}
        >
          <button style={{ padding: "20px", fontSize: "16px", backgroundColor: "antiquewhite", border: "solid 1px black" }}>
            Welcome to DrayTalk <br />
            <br /> Please enable your browser notifications
          </button>
        </div>
      )}
      <Stack spacing={2} sx={{ maxWidth: 600 }}>
        <audio ref={audioRef} src={ringtone} preload="auto" />
        <Snackbar
          style={{ cursor: "pointer" }}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={open}
          autoHideDuration={2000}
          onClose={handleClose}
          onClick={onNotificationClick}
          message={<>{msg}</>}
          action={
            <>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </>
          }
        />
      </Stack>
    </div>
  );
};

export default MessageNotification;

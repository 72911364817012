import React, { useState } from 'react'
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import Tree from './Tree-Custom';

const TreeRuleContainer = ({ orgName, chart, children, isCallingDisable, setIsCallingDisable }) => {

    const [childVisible, setChildVisible] = useState(true);

    const hasChild = chart?.children ? true : false;
    return (
        <>

            <div className='disable-calling-flag'>
                <input type="checkbox" checked={!isCallingDisable} onChange={(e) => setIsCallingDisable(!isCallingDisable)} name="" id="" required />
                <label >Calling Enable</label>
            </div>

            <div className="tree-box-inside">
                <ul className="d-flex d-tree-container flex-column">
                    <li className=" border-0">
                        <div className="d-flex align-items-center">
                            <div onClick={() => setChildVisible(!childVisible)}>
                                {hasChild && (
                                    <>
                                        {childVisible ? (
                                            <RemoveIcon className="d-tree-icon" />
                                        ) : (
                                            <AddIcon className="d-tree-icon" />
                                        )}
                                    </>
                                )}
                            </div>

                            <div className='user-head'>
                                <h4 className={`depart-head`}>
                                    {orgName}
                                </h4>
                            </div>

                        </div>

                        {hasChild && childVisible && <>
                            <div className="tree-box-inside">
                                <Tree data={[chart]} />
                            </div>
                        </>
                        }

                        {children}
                    </li>
                </ul>
            </div>
        </>
    )
}

export default TreeRuleContainer
import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import SubMenuItem from './SubMenuItem'
import { NavLink } from 'react-router-dom'
import { SidebarContext } from '@twilio-paste/core'
import SubMenuLayout from './SubMenuLayout'
import { handleIcon } from '../../../utils/helperPages'

const MenuItem = ({ data, depthLevel }) => {

    const { title, submenu, url, icon } = data

    const { isToggle, isCollapse, setIsToggle, setIsCollapse } = useContext(SidebarContext)



    return (
        <>
            <div className='mb-3'>
                {
                    !submenu || depthLevel === 1 ?
                        <NavLink
                            to={url}
                            className="sidebar__link"
                            key=""
                        >
                            <div className={`${isToggle ? "wrapper__sidebar_hide" : ""} d-flex align-items-center gap-2 p-2 wrapper__sidebar`}>
                                {/* <ChatBubbleOutlineIcon /> */}
                                {depthLevel === 0 && handleIcon(title.toLowerCase())}
                                {
                                    <h6 className='sidebar__heading px-2'>
                                        {title}
                                    </h6>
                                }
                            </div>
                        </NavLink> : <>
                            {
                                depthLevel < 1 && <SubMenuLayout title={title} data={data} depthLevel={depthLevel} >
                                    <SubMenuItem depthLevel={depthLevel} data={submenu} />
                                </SubMenuLayout>
                            }
                        </>
                }
            </div>

        </>
    )
}

export default MenuItem
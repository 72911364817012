import React from "react";
import { AvatarGroup as PasteAvatarGroup } from "@twilio-paste/avatar";
import Avatar from "./Avatar";
import { IconSize } from "@twilio-paste/style-props";

type AvatarGroupProps = {
  names: string[];
  size?: IconSize;
};

const AvatarGroup: React.FC<AvatarGroupProps> = ({ names, size }) => {
  return (
    // <PasteAvatarGroup
    //   size={size ?? "sizeIcon70"}
    //   variant="user"
    //   children={names.map((name, index) => (
    //     <Avatar name={name} size={size} key={index} />
    //   ))}
    // />
    <PasteAvatarGroup
      size={size ?? "sizeIcon70"}
      variant="user"
    >
      {names
        .filter((name) => name.length > 0) // Only include names with length > 0
        .map((name, index) => (
          <Avatar name={name} size={size} key={index} />
        ))}
    </PasteAvatarGroup>
  );
};

export { AvatarGroup };
export default AvatarGroup;

import React from "react";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";

const LogoutButton = ({ isLogout }) => {
  const { instance } = useMsal();
  const navigate = useNavigate();
  const userType = localStorage.getItem("userType");

  const handleLogout = () => {
    if (userType !== "DrayTalk-DrayTab") {
      // Try to get the active account first
      let currentAccount = instance.getActiveAccount();
      
      // If no active account, try to get the first available account
      if (!currentAccount) {
        const accounts = instance.getAllAccounts();
        console.log(accounts)
        if (accounts.length > 0) {
          currentAccount = accounts[0];
        }
      }

      // Logging to ensure account detection
      console.log("currentAccount:", currentAccount);
      
      if (currentAccount) {
        console.log('logging out')
        // instance.logoutRedirect({
        //   account: currentAccount, 
        //   postLogoutRedirectUri: window.location.origin, 
        // });
        localStorage.clear(); // Clears all localStorage entries
        sessionStorage.clear(); // Clears all sessionStorage entries

        if ('caches' in window) {
          caches.keys().then((names) => {
            names.forEach((name) => {
              caches.delete(name); // Deletes all cache entries
            });
          });
        }
        document.cookie.split(";").forEach((cookie) => {
          const name = cookie.split("=")[0].trim();
          document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        });
        
        instance.logoutPopup({
          account: currentAccount,
          mainWindowRedirectUri: '/logout',
          postLogoutRedirectUri: window.location.origin,  // Or any other URL you prefer
        })
        .then(() => {
          console.log("Successfully logged out via popup.");
          navigate("/logout");
        })
        .catch((error) => {
          console.error("Logout error:", error);
        });

      } else {
        console.error("No active account found for logout.");
        window.alert("No active account found for logout.");
      }
    } else {
      // Handle logout for "DrayTalk-DrayTab" users
      localStorage.removeItem("accessToken");
      navigate("/draytab/logout");
    }
  };

  React.useEffect(() => {
    if (isLogout) {
      handleLogout();
    }
  }, [isLogout]);

  return <div>Logout</div>;
};

export default LogoutButton;

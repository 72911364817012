import React, { useContext, useState } from 'react'
import { useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../store'
import AccessContext from '../../context/AccessContext'
import { Col, Container, Row } from 'react-bootstrap'
import PopupLayout from '../UI/Popup/PopupLayout'
import ThemeBtn from '../UI/ThemeBtn'
import { AssignCustomRuleToExistingUser, AssignRuleToExistingUser, CreateRuleSet, UpdateRole, UserDetails } from '../../axios/apis'
import { successNotification, unexpectedErrorNotification } from '../../helpers'
import { generateUniqueValue } from '../../utils/helperPages'
import RequiredAstrict from '../UI/RequiredAstrict'

const UserRuleUpdateModal = ({ open, onClose, userData, callingDisable, title, btn, fetchDetails }) => {

    const [submitloader, setSubmitLoader] = useState(false)

    const [isStored, setIsStored] = useState("")
    const [ruleName, setRuleName] = useState("")

    const dispatch = useDispatch();
    const { addNotifications } = bindActionCreators(actionCreators, dispatch);

    const { selectedDepartments, selectedUsers, isAddrule, setIsAddRule, orgzName, singleUser } = useContext(AccessContext);

    const handleChangeRole = () => {
        if (userEmail === userData.email) {
            window.location.reload();
            // localStorage.setItem("user-role", userData.userRole)
        } else {
            return
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setSubmitLoader(true)
        if (singleUser.userRole !== userData.userRole) {
            try {
                const response = await UpdateRole({ userEmailId: userData.email, newRole: userData.userRole });
                handleChangeRole()
                if (response.status === 200) {
                    callRule()
                }
            } catch (error) {
                // console.log(error)
                unexpectedErrorNotification(error?.response?.data || "An error occurred while Updating the User", addNotifications);
                setSubmitLoader(false)
            }
        } else {
            callRule()
        }

    }

    const callRule = async () => {
        if (isStored === "yes") {
            const payloadRule = {
                customer: orgzName.organizationName,
                ruleSetName: ruleName,
                departmentIdList: selectedDepartments,
                userIdList: selectedUsers,
                isCallingDisabled: callingDisable
            }
            try {

                const resRule = await CreateRuleSet(payloadRule)
                if (resRule.status === 200) {
                    try {
                        const resAssignRule = await AssignRuleToExistingUser({ userEmailId: userData.email, ruleSetId: resRule.data.ruleSetId })
                        if (resAssignRule.status === 200) {
                            successNotification({
                                message: `User Profile Updated Successfully`,
                                addNotifications,
                            });
                            fetchDetails("");
                            setSubmitLoader(false);
                            onClose();
                        }
                    } catch (error) {
                        onClose()
                        unexpectedErrorNotification(error?.response?.data || "An error occurred while Updating the User", addNotifications);
                        setSubmitLoader(false)
                        // console.log(error)
                    }
                }
            } catch (error) {
                unexpectedErrorNotification(error?.response?.data || "An error occurred while Updating the User", addNotifications);
                setSubmitLoader(false)
                // console.log(error)
            }
        } else {
            const payload = {
                userEmailId: userData.email,
                userRuleSet: {
                    customer: orgzName.organizationName,
                    ruleSetName: `Rule_${generateUniqueValue()}`,
                    departmentIdList: selectedDepartments,
                    userIdList: selectedUsers,
                    isCallingDisabled: callingDisable
                }
            }
            try {
                const resCustomRule = await AssignCustomRuleToExistingUser(payload)
                if (resCustomRule.status === 200) {
                    successNotification({
                        message: "User Updated Successfully",
                        addNotifications,
                    });
                    setSubmitLoader(false)
                    onClose()
                    fetchDetails("")
                }
            } catch (error) {
                onClose()
                unexpectedErrorNotification(error?.response?.data || "An error occurred while Updating the User", addNotifications);
                setSubmitLoader(false)
                // console.log(error)
            }
        }
    }

    return (
        <PopupLayout open={open} onClose={onClose} title={title} handleSubmit={handleSubmit} >
            <Col lg={12}>
                <div className="input-container">
                    <p className='popup-text'>Do you want to Save current selection as Stored Rule?</p>
                    <div className='radio-input'>
                        <input type="radio" name="stored" value="yes" onChange={(e) => setIsStored(e.target.value)} id="" />
                        <span>Yes</span>
                    </div>
                    <div className='radio-input'>
                        <input type="radio" name="stored" value="no" onChange={(e) => setIsStored(e.target.value)} id="" />
                        <span>No</span>
                    </div>
                </div>
            </Col>

            {
                isStored === "yes" && <Col lg={12}>
                    <div className="input-box">
                        <label className='input-label' htmlFor="">Rule Name <RequiredAstrict /></label>
                        <div className="input-field">
                            <input type="text" name="userName" value={ruleName} onChange={(e) => setRuleName(e.target.value)} required />
                        </div>
                    </div>
                </Col>

            }

            <div className='d-flex justify-content-end gap-4'>
                <button className='cancel-btn' onClick={onClose}>Cancel</button>
                <ThemeBtn name={btn} loader={submitloader} type="submit" />
            </div>
        </PopupLayout>
    )
}

export default UserRuleUpdateModal
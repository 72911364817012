import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { handleIcon } from '../../../utils/helperPages';
import SidebarContext from '../../../context/SidebarContext';

const SubMenuLayout = ({ title, children, data, depthLevel }) => {


    const location = useLocation();
    const currentPath = location.pathname;

    const [subMenuOpen, setSubMenuOpen] = useState(false);

    const { isToggle, isCollapse, setIsToggle, setIsCollapse } = useContext(SidebarContext)


    useEffect(() => {
        // Check if any of the submenu items' paths match the current path
        if (data.submenu.some(item => currentPath.includes(item.url))) {
            // setSubMenuOpen(true);
        }
    }, [currentPath, data.submenu]);

    useEffect(() => {
        if (!isToggle) {
            setSubMenuOpen(false);
            // setOpenSubMenu("")
        }
    }, [isToggle])

    const handleToggle = (e) => {
        e.preventDefault();
        console.log("Clicked")
        setSubMenuOpen(!subMenuOpen);
    };

    return (
        <div>
            <div style={{ cursor: "pointer" }} className={`${isToggle ? "wrapper__sidebar_hide" : ""} d-flex align-items-center justify-content-between gap-1 p-2 wrapper__sidebar`} onClick={handleToggle}>
                <div className='d-flex align-items-center gap-2'>
                    {depthLevel === 0 && handleIcon(title.toLowerCase())}
                    {
                        <h6 className='sidebar__heading px-2'>
                            {title}
                        </h6>
                    }
                </div>
                {/* <ChatBubbleOutlineIcon /> */}
                <KeyboardArrowDownIcon className={`transition w-5 duration-300 ${subMenuOpen ? 'rotate-180' : ''}`} />
            </div>

            {
                subMenuOpen && (children)
            }
        </div>
    )
}

export default SubMenuLayout
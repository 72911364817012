import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store } from "./store";
import { Theme } from "@twilio-paste/core/theme";
import { Box } from "@twilio-paste/core";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import App from "./components/App";
import styles from "../src/styles";
import { AuthProvider } from "./context/AuthContext";
import { AppReadyProvider } from "./context/AppReadyContext";
import { NotificationProvider } from "./context/NotificationContext";
import { ClientProvider } from "./context/ClientInfoContext";
import { SearchProvider } from "./context/SearchContext";
import { AccessProvider } from "./context/AccessContext";
import { UpdateConvoNameProvider } from "./context/UpdateConvoName";
import { ReportProvider } from "./context/ReportContext";
import { VideoProvider } from "./components/Video-App/components/VideoProvider";
import { ParticipantProvider } from "./components/Video-App/components/ParticipantProvider";
import ErrorDialog from "./components/Video-App/components/ErrorDialog/ErrorDialog";
import { ChatProvider } from "./components/Video-App/components/ChatProvider";
import AppStateProvider, { useAppState } from "./components/Video-App/state";
import useConnectionOptions from "./components/Video-App/utils/useConnectionOptions/useConnectionOptions";
import { MuiThemeProvider } from "@material-ui/core/styles";
import theme from "./components/Video-App/theme";
import { MessageProvider } from "./context/MessageContext";
import { SidebarProvider } from "./context/SidebarContext";

const VideoApp = () => {
  const { error, setError } = useAppState();
  const connectionOptions = useConnectionOptions();

  return (
    <VideoProvider options={connectionOptions} onError={setError}>
      <ErrorDialog dismissError={() => setError(null)} error={error} />
      <ParticipantProvider>
        <ChatProvider>
          <App />
        </ChatProvider>
      </ParticipantProvider>
    </VideoProvider>
  );
};

export const ReactApp = () => (
  <Box style={styles.app}>
    <React.StrictMode>
      <MuiThemeProvider theme={theme}>
        <Provider store={store}>
          <Theme.Provider theme="twilio">
            <Box style={styles.app}>
              <AppReadyProvider>
                <AuthProvider>
                  <MessageProvider>
                    <AppStateProvider>
                      <ClientProvider>
                        <NotificationProvider>
                          <UpdateConvoNameProvider>
                            <SearchProvider>
                              <AccessProvider>
                                <ReportProvider>
                                  <SidebarProvider>
                                    <VideoApp />
                                  </SidebarProvider>
                                </ReportProvider>
                              </AccessProvider>
                            </SearchProvider>
                          </UpdateConvoNameProvider>
                        </NotificationProvider>
                      </ClientProvider>
                    </AppStateProvider>
                  </MessageProvider>
                </AuthProvider>
              </AppReadyProvider>
            </Box>
          </Theme.Provider>
        </Provider>
      </MuiThemeProvider>
    </React.StrictMode>
  </Box>
);

ReactDOM.render(<ReactApp />, document.getElementById("root"));

import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { UserDetails } from "../../../axios/apis";
import ConstructionIcon from "@mui/icons-material/Construction";

const OrgInfo = () => {
  const [org, setOrg] = useState({organisationName: "", roles: []});

  const fetchServiceProvider = async () => {
    try {
      // const res = await ServiceProvider()
      const res = await UserDetails();
      console.log("Response: ", res.data);
      setOrg(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchServiceProvider();
  }, []);

  return (
    <div className="provider-container flex">
      <div class="relative group"></div>
      <div className="relative">
        <div className="flex items-center bg-white text-theme rounded-md cursor-pointer transition-all duration-500 ease-in-out pr-2">
          <ConstructionIcon className="m-1" />
          <div
            className={`ml-2 py-1 overflow-hidden whitespace-nowrap transition-all duration-500 ease-in-out opacity-100`}
          >
            <div className="leading-4">
              <div className="text-[14px]">
                <span className="font-bold">Organization :</span>
                <span className="font-semibold">
                  {" "}
                  {org.customers ? org.customers : "Dray Data"}
                </span>
              </div>

              <div className="text-[14px]">
                <span className="font-bold">Role :</span>
                <span className="font-semibold">
                  {" "}
                  {org.roles.length > 0
                    ? org.roles.join(", ")
                    : "No Service Providers Selected"}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrgInfo;

import { createContext, useState } from "react";

const SidebarContext = createContext();

export const SidebarProvider = ({ children }) => {

    const [isToggle, setIsToggle] = useState(false)
    const [isCollapse, setIsCollapse] = useState(false)

    const [openSubMenu, setOpenSubMenu] = useState(null);

    return (
        <SidebarContext.Provider value={{ isToggle, setIsToggle, isCollapse, setIsCollapse, openSubMenu, setOpenSubMenu }}>
            {children}
        </SidebarContext.Provider>
    );
};

export default SidebarContext;

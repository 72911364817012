import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import ConversationView from "./ConversationView";
import {
  SetParticipantsType,
  SetSidType,
  SetUnreadMessagesType,
} from "../../types";
import { actionCreators, AppState } from "../../store";
import { getTypingMessage, unexpectedErrorNotification } from "../../helpers";
import { ReduxConversation } from "../../store/reducers/convoReducer";
import { getSdkConversationObject } from "../../conversations-objects";
import { ReduxMessage } from "../../store/reducers/messageListReducer";
import UpdateConvoNameContext from "../../context/UpdateConvoName";
import { useContext, useEffect, useState } from "react";
import SkletonUserList from "../UI/SkletonUserList";
import AccessContext from "../../context/AccessContext";
import NoAccountsOutlinedIcon from "@mui/icons-material/NoAccountsOutlined";
import { MessageContext } from "../../context/MessageContext";
import { RecentCallLogs, UpdateMissedCall } from "../../axios/apis";

function getLastMessage(messages: ReduxMessage[], typingData: string[]) {
  if (messages === undefined || messages === null) {
    return "Loading...";
  }
  if (typingData.length) {
    return getTypingMessage(typingData);
  }
  if (messages.length === 0) {
    return "No messages";
  }
  return messages[messages.length - 1].body || "Media message";
}

function isMyMessage(messages: ReduxMessage[]) {
  if (messages === undefined || messages === null || messages.length === 0) {
    return false;
  }
  return messages[messages.length - 1].author === localStorage.getItem("user")
    ? messages[messages.length - 1]
    : false;
}

async function updateCurrentConvo(
  setSid: SetSidType,
  convo: ReduxConversation,
  updateParticipants: SetParticipantsType
) {
  setSid(convo.sid);
  const participants = await getSdkConversationObject(convo).getParticipants();
  updateParticipants(participants, convo.sid);
  await UpdateMissedCall({sid: convo.sid, missedCallStatus: false});
  console.log("Clear miss call");
}

async function leavePage(setSid: SetSidType) {
  setSid("");
}

function setUnreadMessagesCount(
  currentconvoSid: string,
  convoSid: string,
  unreadMessages: Record<string, number>,
  updateUnreadMessages: SetUnreadMessagesType
) {
  if (currentconvoSid == convoSid && unreadMessages[convoSid] !== 0) {
    updateUnreadMessages(convoSid, 0);
    return 0;
  }
  if (currentconvoSid == convoSid) {
    return 0;
  }
  return unreadMessages[convoSid];
}

const ConversationsList: React.FC = () => {
  const sid = useSelector((state: AppState) => state.sid);
  const conversations = useSelector((state: AppState) => state.convos);
  const messages = useSelector((state: AppState) => state.messages);
  const unreadMessages = useSelector((state: AppState) => state.unreadMessages);
  const participants = useSelector((state: AppState) => state.participants);
  const typingData = useSelector((state: AppState) => state.typingData);
  const { userListLoader, missedCalls, setMissedCalls } =
    useContext(AccessContext);
  const { setCurrentConvo } = useContext(MessageContext);
  const use24hTimeFormat = useSelector(
    (state: AppState) => state.use24hTimeFormat
  );
  const { convoName, setConvoName } = useContext(UpdateConvoNameContext);

  const [showList, setShowList] = useState(false);

  const dispatch = useDispatch();
  const {
    updateCurrentConversation,
    updateParticipants,
    updateUnreadMessages,
    setLastReadIndex,
    addNotifications,
  } = bindActionCreators(actionCreators, dispatch);
  useEffect(() => {
    fetchCallLogs();
  }, [sid]); // Empty dependency array ensures this runs only once after the initial render

  const fetchCallLogs = async () => {
    try {
      const callLogs = await RecentCallLogs(localStorage.getItem("user"));

      const mostRecentLogs = Object.values(
        callLogs.data.reduce((acc: any, log: any) => {
          if (
            !acc[log.conversationSid] ||
            new Date(log.createdAt) >
              new Date(acc[log.conversationSid].createdAt)
          ) {
            acc[log.conversationSid] = log;
          }
          return acc;
        }, {})
      );
      setMissedCalls(mostRecentLogs);
    } catch (error) {
      console.error("Error fetching call logs:", error);
    }
  };

  if (
    conversations === undefined ||
    conversations === null ||
    conversations.length === 0
  ) {
    return (
      <>
        {!userListLoader ? (
          <div className="empty-list">
            <NoAccountsOutlinedIcon />
            <span> User Not Found</span>
          </div>
        ) : (
          <SkletonUserList />
        )}
      </>
    );
  }

  return (
    <>
      {userListLoader && <SkletonUserList />}
      <div id="conversation-list">
        {conversations.map((convo) => (
          <ConversationView
            use24hTimeFormat={use24hTimeFormat}
            key={convo.sid}
            convoId={convo.sid}
            setSid={updateCurrentConversation}
            currentConvoSid={sid}
            lastMessage={
              getLastMessage(
                messages[convo.sid],
                typingData[convo.sid] ?? []
              ) ?? ""
            }
            messages={messages[convo.sid]}
            typingInfo={typingData[convo.sid] ?? []}
            myMessage={isMyMessage(messages[convo.sid])}
            unreadMessagesCount={setUnreadMessagesCount(
              sid,
              convo.sid,
              unreadMessages,
              updateUnreadMessages
            )}
            updateUnreadMessages={updateUnreadMessages}
            participants={participants[convo.sid] ?? []}
            convo={convo}
            onClick={async () => {
              try {
                setLastReadIndex(convo.lastReadMessageIndex ?? -1);
                setCurrentConvo(convo.sid);
                await updateCurrentConvo(
                  updateCurrentConversation,
                  convo,
                  updateParticipants
                );
                //update unread messages
                updateUnreadMessages(convo.sid, 0);
                //set messages to be read
                const lastMessage =
                  messages[convo.sid].length &&
                  messages[convo.sid][messages[convo.sid].length - 1];
                if (lastMessage && lastMessage.index !== -1) {
                  await getSdkConversationObject(
                    convo
                  ).advanceLastReadMessageIndex(lastMessage.index);
                }
              } catch (e) {
                // unexpectedErrorNotification(e.message, addNotifications);
              }
            }}
          />
        ))}
      </div>
    </>
  );
};

export default ConversationsList;

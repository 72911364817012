import React, { useContext, useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import SearchContext from "../../context/SearchContext";
import AccessContext from "../../context/AccessContext";
import { doesConversationExistWithEmail, readUserProfile } from "../../api";
import { doesConversationExistWithEmails } from "../../api";
import { useClient } from "../../context/ClientInfoContext";
import { actionCreators } from "../../store";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Conversation } from "@twilio/conversations";
import { unexpectedErrorNotification } from "../../helpers";
import { createConversation, createGroupConversation } from "../../api";
import ToolTipHover from "../UI/ToolTipHover";
import { Client } from "@twilio/conversations";
import ChatIcon from "@mui/icons-material/Chat";
import { Phone, MessageSquareText } from "lucide-react";
import { v4 as uuidv4 } from "uuid";
import { getTitle } from "../../api";
import { sendMessage } from "../../firebase-config";
import { MessageContext } from "../../context/MessageContext";
const Tree = ({ data = [] }) => {
  return (
    <div className="">
      <ul className="d-flex d-tree-container flex-column">
        {data.map((item, index) => (
          <>
            <TreeNode node={item} index={index} totalNodes={data.length} />
            {item.otherContactList && (
              <OtherContactTree
                node={item.otherContactList}
                parentIndex={index}
                totalNodes={item.otherContactList.length}
              />
            )}
          </>
        ))}
      </ul>
    </div>
  );
};

const TreeNode = ({ node, index, totalNodes }) => {
  const [childVisible, setChildVisible] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const token = useSelector((state) => state.token);
  const { updateCurrentConversation, addNotifications, updateParticipants } =
    bindActionCreators(actionCreators, dispatch);
  const { client, updateConnectionState, updateConversation } = useClient();
  const hasChild = node.children ? true : false;

  const { searchText, findList, searchEmail } = useContext(SearchContext);
  const { setSingleChart, setIsChatActive, orgChart } =
    useContext(AccessContext);
  const room = uuidv4();
  const [participantName, setParticipantName] = useState(null);
  const [participantEmail, setParticipantEmail] = useState(null);
  const {
    message,
    setMessage,
    accepted,
    setAccepted,
    setRejected,
    setIsCallingType,
  } = useContext(MessageContext);
  let homePath = "/";
  if (localStorage.getItem("userType") == "DrayTalk-DrayTab") {
    homePath = "/draytab/chat";
  }
  const includesSearchTerm = (text) =>
    text.toLowerCase() === searchText.toLowerCase();

  useEffect(() => {
    // const matchedTitles = searchTitle(searchText)
    findList.map((name) => {
      if (name === node.department) {
        setChildVisible(true);
      }
    });
  }, [searchText, searchEmail, findList, node]);

  useEffect(() => {
    if (node.treeLevel === 1) setChildVisible(true);
    // node.userContactList.map((el) => {
    //   if (el.parentId === 0) setChildVisible(true);
    // });
  }, []);

  const handleClick = (item) => {
    createNewConversation(item);
  };
  const createNewConversation = async (file) => {
    setIsChatActive(true);
    console.log(file);
    try {
      if (file.userEmailId == localStorage.getItem("user")) {
        unexpectedErrorNotification(
          "Cannot create conversation with self!",
          addNotifications
        );
        setIsChatActive(false);
        return;
      } else {
        const exists = await doesConversationExistWithEmail(client, [file]);
        console.log(exists);

        if (exists) {
          updateConversation(exists);
          updateCurrentConversation(exists.sid);
          setIsChatActive(false);
          navigate(homePath);
        } else {
          const conv = await createConversation(
            file.userEmailId,
            updateParticipants,
            client,
            addNotifications
          );
          // const castedConversation = conv as Conversation;
          const castedConversation = conv instanceof Conversation ? conv : null;

          updateConversation(castedConversation);
          updateCurrentConversation(castedConversation.sid);
          setIsChatActive(false);
          navigate(homePath);
        }
      }
    } catch (error) {
      // Handle errors appropriately
      setIsChatActive(false);
      console.error("Error creating conversation:", error);
    }
  };

  const createGroup = async (file) => {
    setIsChatActive(true);
    try {
      const exists = await doesConversationExistWithEmails(client, file);
      if (exists) {
        updateConversation(exists);
        updateCurrentConversation(exists.sid);
        setIsChatActive(false);
        navigate(homePath);
      } else {
        const conv = await createGroupConversation(
          file,
          updateParticipants,
          client,
          addNotifications
        );
        // const castedConversation = conv as Conversation;
        const castedConversation = conv instanceof Conversation ? conv : null;

        updateConversation(castedConversation);
        updateCurrentConversation(castedConversation.sid);
        setIsChatActive(false);
        navigate(homePath);
      }
    } catch (error) {
      setIsChatActive(false);
      console.error("Error creating conversation:", error);
    }
  };

  const handleDepartmentClick = (departmentContactList) => {
    if (departmentContactList.length == 0) {
      return;
    } else if (departmentContactList.length == 1) {
      createGroup(departmentContactList);
    } else {
      createGroup(departmentContactList);
    }
  };

  const startCall = async (file) => {
    console.log(file);
    setIsChatActive(true);
    let SID = "";
    try {
      if (file.userEmailId == localStorage.getItem("user")) {
        unexpectedErrorNotification("Cannot call yourself!", addNotifications);
        setIsChatActive(false);
        return;
      } else {
        const exists = await doesConversationExistWithEmail(client, [file]);
        console.log("Exists: ", exists);

        if (exists) {
          updateConversation(exists);
          SID = exists.sid;
          updateCurrentConversation(exists.sid);
          setIsChatActive(false);
          // navigate(homePath);
        } else {
          const conv = await createConversation(
            file.userEmailId,
            updateParticipants,
            client,
            addNotifications
          );
          // const castedConversation = conv as Conversation;
          const castedConversation = conv instanceof Conversation ? conv : null;

          updateConversation(castedConversation);
          SID = castedConversation ? castedConversation.sid : "";
          updateCurrentConversation(
            castedConversation ? castedConversation.sid : ""
          );
          setIsChatActive(false);
          // navigate(homePath);
        }
        if (SID) {
          handleCallButtonClick(SID);
        }
      }
    } catch (error) {
      // Handle errors appropriately
      setIsChatActive(false);
      console.error("Error making a call:", error);
    }
  };

  const startGroupCall = async (departmentContactList) => {
    console.log(departmentContactList);
    let SID = "";
    try {
      const exists = await doesConversationExistWithEmails(
        client,
        departmentContactList
      );
      if (exists) {
        console.log("Conversation Exists");
        updateConversation(exists);
        updateCurrentConversation(exists.sid);
        SID = exists.sid;
        setIsChatActive(false);
        // navigate(homePath);
      } else {
        console.log("Conversation Doesn't Exists");

        const conv = await createGroupConversation(
          departmentContactList,
          updateParticipants,
          client,
          addNotifications
        );
        // const castedConversation = conv as Conversation;
        const castedConversation = conv instanceof Conversation ? conv : null;

        updateConversation(castedConversation);
        updateCurrentConversation(
          castedConversation ? castedConversation.sid : null
        );
        SID = castedConversation ? castedConversation.sid : null;
        setIsChatActive(false);
        // navigate(homePath);
      }
      if (SID) {
        handleCallButtonClick(SID);
      }
    } catch (error) {
      setIsChatActive(false);
      console.error("Error calling group:", error);
    }
  };

  const handleCallButtonClick = async (sid) => {
    const names = await getTitle(sid);
    console.log(names);
    const participantNames = [];
    const participantEmails = [];

    if (names) {
      if (names.length === 1) {
        participantNames.push(findUserByEmail(orgChart[0], names[0]));
        participantEmails.push(findUserEmail(orgChart[0], names[0]));
      } else if (names.length > 1) {
        // for (let i = 0; i < Math.min(names.length, 3); i++) {
        //   participantNames.push(findUserByEmail(orgChart[0], names[i]));
        // }
        for (let i = 0; i < names.length; i++) {
          participantEmails.push(findUserEmail(orgChart[0], names[i]));
          participantNames.push(findUserByEmail(orgChart[0], names[i]));
        }
      } else {
        participantNames.push("No Participant");
        // participantNames.push(localStorage.getItem('userName'))
      }
      setParticipantName(participantNames.join(", "));
      setParticipantEmail(participantEmails.join(", "));
      // setParticipantLoader(false);
    }

    const sentBy = localStorage.getItem("user");
    const sentTo = participantEmails.join(", ")?.split(", ");
    const type = "Call";
    const room = uuidv4();
    // const room = localStorage.getItem("uuid");
    const ToNames = participantNames.join(", ")?.split(", ");
    const from = localStorage.getItem("userName");
    const comment = "some comment";
    const seen = false;
    const status = "initial";

    console.log(ToNames);
    console.log(sentTo);

    // Call the sendMessage function with the message parameters
    await sendMessage(
      {
        text: "Incoming Call",
        sentBy: sentBy,
        sentTo: sentTo,
        type: type,
        room: room,
        conversationSid: sid,
        names: ToNames,
        from: from,
        comment: comment,
        seen: seen,
        status: status,
      },
      sentTo,
      "Call"
    );
    // localStorage.setItem("roomId", room)
    setMessage({
      text: "",
      sentBy: sentBy,
      sentTo: sentTo,
      type: type,
      Room: room,
      conversationSid: sid,
      names: ToNames,
      from: from,
      comment: comment,
      seen: seen,
      status: status,
    });
    setAccepted(true);
    setIsCallingType("Calling");
    setRejected(false);
  };

  const someFunction = async (email) => {
    // const client = new Client(token)
    // try {
    //   const user = await readUserProfile(email, client);
    //   if (user) {
    //     return true
    //   } else {
    //     return false
    //   }
    // } catch (error) {
    //   console.error("Error:", error);
    // }
    return true;
  };

  // UserContact and TreeNode as JSDoc types
  /**
   * @typedef {Object} UserContact
   * @property {string} id
   * @property {string} userName
   * @property {string} userEmailId
   * @property {string} userJobRole
   * @property {string} userDepartment
   * @property {number} parentId
   */

  /**
   * @typedef {Object} TreeNode
   * @property {string} department
   * @property {UserContact[]} userContactList
   * @property {TreeNode[]} children
   */

  /**
   * Finds a user by email in a tree structure.
   * @param {TreeNode | null} tree - The tree to search.
   * @param {string} email - The email of the user to find.
   * @returns {string | null} The user name if found, otherwise null.
   */
  const findUserByEmail = (tree, email) => {
    if (!tree) return null;

    // Check the current level of the tree
    const user = tree.userContactList.find(
      (user) => user.userEmailId === email
    );
    if (user) {
      return user.userName; // Found the user at the current level
    }

    // If not found at the current level, recursively search in children
    for (const child of tree.children) {
      const result = findUserByEmail(child, email);
      if (result) {
        return result; // Found in the children
      }
    }

    return null; // Not found in the current branch
  };
  const findUserEmail = (tree, email) => {
    if (!tree) return null;
    // Check the current level of the tree
    const user = tree.userContactList.find(
      (user) => user.userEmailId === email
    );
    if (user) {
      return user.userEmailId; // Found the user at the current level
    }

    // If not found at the current level, recursively search in children
    for (const child of tree.children) {
      const result = findUserEmail(child, email);
      if (result) {
        return result; // Found in the children
      }
    }

    return null; // Not found in the current branch
  };

  return (
    <>
      <li
        className="d-tree-node"
        style={{ borderLeft: "2px solid black !important" }}
      >
        <div
          className="d-flex align-items-center"
          // onClick={() => setChildVisible(!childVisible)}
        >
          {hasChild && (
            <>
              {childVisible ? (
                <RemoveIcon
                  className="d-tree-icon"
                  onClick={() => setChildVisible(!childVisible)}
                />
              ) : (
                <AddIcon
                  className="d-tree-icon"
                  onClick={() => setChildVisible(!childVisible)}
                />
              )}
            </>
          )}

          <div>
            <h4
              className={`depart-head d-flex gap-3  ${
                includesSearchTerm(node.department) && "highlight"
              }`}
            >
              <div style={{ cursor: "default" }}>
                {/* <ToolTipHover userExist={true} title={"Click to Start a Group Chat"} position={"right"}> */}
                {node.department}
                {/* </ToolTipHover> */}
              </div>
              <div
                className="contact-chat-icon"
                onClick={() => handleDepartmentClick(node.userContactList)}
              >
                <ToolTipHover
                  userExist={true}
                  title={`Click to Chat with ${node.department}`}
                  position={"top"}
                >
                  <MessageSquareText style={{ cursor: "pointer" }} />
                </ToolTipHover>
              </div>
              {localStorage.getItem("callingDisable") === "true" ? (
                <></>
              ) : (
                <div
                  className="contact-chat-icon"
                  onClick={() => startGroupCall(node.userContactList)}
                >
                  <ToolTipHover
                    userExist={true}
                    title={`Click to Call ${node.department}`}
                    position={"top"}
                  >
                    <Phone style={{ cursor: "pointer" }} />
                  </ToolTipHover>
                </div>
              )}
            </h4>
          </div>
        </div>

        {hasChild && childVisible && (
          <div className="d-tree-content">
            <div>
              <ul className="d-tree-container">
                {node.userContactList.map((item) => (
                  <li className={`d-tree-people`}>
                    <div className="d-people-node">
                      <span
                        style={{ cursor: "default" }}
                        className={`people-head d-flex gap-3 ${
                          item.userEmailId === searchEmail && "highlight"
                        } ${!someFunction(item.userEmailId) && "disable-user"}`}
                      >
                        {/* <ToolTipHover userExist={someFunction(item.userEmailId)} title={`Click to Chat with ${item.userName}`} position={"right"}> */}
                        {item.userName}
                        {/* </ToolTipHover> */}
                        <div
                          className="contact-chat-icon"
                          onClick={() => handleClick(item)}
                        >
                          <ToolTipHover
                            userExist={someFunction(item.userEmailId)}
                            title={`Click to Chat with ${item.userName}`}
                            position={"top"}
                          >
                            <MessageSquareText style={{ cursor: "pointer" }} />
                          </ToolTipHover>
                        </div>
                        {localStorage.getItem("callingDisable") === "true" ? (
                          <></>
                        ) : (
                          <div
                            className="contact-chat-icon"
                            onClick={() => startCall(item)}
                          >
                            <ToolTipHover
                              userExist={someFunction(item.userEmailId)}
                              title={`Click to Call ${item.userName}`}
                              position={"top"}
                            >
                              <Phone style={{ cursor: "pointer" }} />
                            </ToolTipHover>
                          </div>
                        )}
                      </span>
                      {/* <div className="status">
                                                    <FiberManualRecordIcon className={`${item.isOnline ? "online" : "offline"}`} />
                                                </div> */}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <ul className="d-flex d-tree-container flex-column">
              <Tree data={node.children} />
            </ul>
          </div>
        )}
      </li>

      {index !== totalNodes - 1 && <hr className="m-0" />}
    </>
  );
};

const OtherContactTree = ({ node, parentIndex, totalNodes }) => {
  const [childVisible, setChildVisible] = useState(false);
  const hasChild = node ? true : false;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const token = useSelector((state) => state.token);
  const { updateCurrentConversation, addNotifications, updateParticipants } =
    bindActionCreators(actionCreators, dispatch);
  const { client, updateConnectionState, updateConversation } = useClient();

  const { searchText, findList, searchEmail } = useContext(SearchContext);
  const { setSingleChart } = useContext(AccessContext);
  let homePath = "/";
  if (localStorage.getItem("userType") == "DrayTalk-DrayTab") {
    homePath = "/draytab/chat";
  }

  // const includesSearchTerm = (text) =>
  //   text.toLowerCase() === searchText.toLowerCase();

  useEffect(() => {
    // const matchedTitles = searchTitle(searchText)
    node.map((item) => {
      if (item.userName === searchText) {
        setChildVisible(true);
      }
    });
  }, [searchText]);

  // useEffect(() => {
  //   if (node.treeLevel === 1) setChildVisible(true);
  //   // node.userContactList.map((el) => {
  //   //   if (el.parentId === 0) setChildVisible(true);
  //   // });
  // }, []);

  const handleClick = (item) => {
    createNewConversation(item);
  };
  const createNewConversation = async (file) => {
    try {
      if (file.userEmailId == localStorage.getItem("user")) {
        unexpectedErrorNotification(
          "Cannot create conversation with self!",
          addNotifications
        );
        return;
      } else {
        const exists = await doesConversationExistWithEmails(client, [file]);

        if (exists) {
          updateConversation(exists);
          updateCurrentConversation(exists.sid);
          navigate(homePath);
        } else {
          const conv = await createConversation(
            file.userEmailId,
            updateParticipants,
            client,
            addNotifications
          );
          // const castedConversation = conv as Conversation;
          const castedConversation = conv instanceof Conversation ? conv : null;

          updateConversation(castedConversation);
          updateCurrentConversation(castedConversation.sid);
          navigate(homePath);
        }
      }
    } catch (error) {
      // Handle errors appropriately
      console.error("Error creating conversation:", error);
    }
  };

  // const createGroup = async (file) => {
  //   try {
  //     const exists = await doesConversationExistWithEmails(client, file);
  //     if (exists) {
  //       updateConversation(exists);
  //       updateCurrentConversation(exists.sid);
  //       navigate("/");
  //     }
  //     else {
  //       const conv = await createGroupConversation(
  //         file,
  //         updateParticipants,
  //         client,
  //         addNotifications
  //       );
  //       // const castedConversation = conv as Conversation;
  //       const castedConversation = conv instanceof Conversation ? conv : null;

  //       updateConversation(castedConversation);
  //       updateCurrentConversation(castedConversation.sid);
  //       navigate("/");
  //     }
  //   } catch (error) {
  //     console.error("Error creating conversation:", error);
  //   }
  // };

  // const handleDepartmentClick = (departmentContactList) => {
  //   if (departmentContactList.length == 0) {
  //     return;
  //   } else if (departmentContactList.length == 1) {
  //     createGroup(departmentContactList);
  //   } else {
  //     createGroup(departmentContactList);
  //   }
  // };

  const someFunction = async (email) => {
    const client = new Client(token);
    try {
      const user = await readUserProfile(email, client);
      if (user) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <li className="d-tree-node border-0">
        <div
          className="d-flex align-items-center"
          // onClick={() => setChildVisible(!childVisible)}
        >
          {hasChild && (
            <>
              {childVisible ? (
                <RemoveIcon
                  className="d-tree-icon"
                  onClick={() => setChildVisible(!childVisible)}
                />
              ) : (
                <AddIcon
                  className="d-tree-icon"
                  onClick={() => setChildVisible(!childVisible)}
                />
              )}
            </>
          )}

          <div>
            <h4 style={{ cursor: "pointer" }} className={`depart-head`}>
              <div>
                <ToolTipHover
                  userExist={true}
                  title={"Click to Start a Group Chat"}
                  position={"right"}
                >
                  Other Contact List
                </ToolTipHover>
              </div>
            </h4>
          </div>
        </div>

        {hasChild && childVisible && (
          <div className="d-tree-content">
            <div>
              <ul className="d-tree-container">
                {node.map((item) => (
                  <li className={`d-tree-people`}>
                    <div className="d-people-node">
                      <span
                        style={{ cursor: "default" }}
                        onClick={() => handleClick(item)}
                        className={`people-head ${
                          item.userEmailId === searchEmail && "highlight"
                        } ${!someFunction(item.userEmailId) && "disable-user"}`}
                      >
                        <ToolTipHover
                          userExist={someFunction(item.userEmailId)}
                          title={`Click to Chat with ${item.userName}`}
                          position={"right"}
                        >
                          {item.userName}
                        </ToolTipHover>
                      </span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            {/* <ul className="d-flex d-tree-container flex-column">
              <Tree data={node.children} />
            </ul> */}
          </div>
        )}
      </li>

      {/* {parentIndex !== totalNodes - 1 && <hr className="m-0" />} */}
    </>
  );
};

export default Tree;

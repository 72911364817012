import React, { useContext, useState } from 'react'
import AccessContext from '../../../context/AccessContext';
import Tree from './Tree-Custom';
import RemoveIcon from "@mui/icons-material/Remove";
import ThemeBtn from '../../UI/ThemeBtn';
import AddIcon from "@mui/icons-material/Add";
import RuleAddModal from '../RuleAddModal';
import TreeRuleContainer from './TreeRuleContainer';
import UserRuleAddModal from '../UserRuleAddModal';
import UserRuleUpdateModal from '../UserRuleUpdateModal';
import GroupRuleAddModal from '../GroupRuleAddModal';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../store';
import { unexpectedErrorNotification } from '../../../helpers';


const CreateCustomRule = ({ onBack, data, fetchData, modal, type }) => {

    const { singleChart, orgzName, selectedUsers, selectedDepartments } = useContext(AccessContext);

    const dispatch = useDispatch();
    const { addNotifications } = bindActionCreators(actionCreators, dispatch);


    const [isConfirm, setIsConfirm] = useState(false)
    const [isCallingDisable, setIsCallingDisable] = useState(false)

    const handleConfirm = () => {
        if (selectedUsers.length === 0 && selectedDepartments.length === 0) {
            unexpectedErrorNotification("Select at Least One user or Group", addNotifications);
        } else {
            setIsConfirm(true)
        }
    }

    console.log(isCallingDisable)

    return (
        <>
            {
                (type !== "Update" || !isConfirm) && <TreeRuleContainer orgName={orgzName.organizationName} chart={orgzName.orgChart} isCallingDisable={isCallingDisable} setIsCallingDisable={setIsCallingDisable}>

                    <div className="action-btn mt-2">
                        <button type="button" onClick={onBack} className="reset-btn">
                            Back
                        </button>
                        <ThemeBtn name="Submit" type="button" handleClick={handleConfirm} />
                    </div>

                </TreeRuleContainer>
            }

            {
                modal === "Department" ? <>
                    {
                        (isConfirm && type === "Add") && <RuleAddModal open={isConfirm} levelData={data} onClose={() => setIsConfirm(false)} callingDisable={isCallingDisable} title={"Create Group"} btn={"Create"} fetchDetails={fetchData} />
                    }
                    {
                        (isConfirm && type === "Update") && <GroupRuleAddModal open={isConfirm} levelData={data} onClose={() => setIsConfirm(false)} callingDisable={isCallingDisable} title={"Update Group"} btn={"Update"} fetchDetails={fetchData} />
                    }
                </> : <>
                    {
                        (isConfirm && type === "Add") && <UserRuleAddModal open={isConfirm} userData={data} onClose={() => setIsConfirm(false)} callingDisable={isCallingDisable} title={"Create User"} btn={"Create"} fetchDetails={fetchData} />
                    }
                    {
                        (isConfirm && type === "Update") && <UserRuleUpdateModal open={isConfirm} userData={data} onClose={() => setIsConfirm(false)} callingDisable={isCallingDisable} title={"Update User"} btn={"Update"} fetchDetails={fetchData} />
                    }
                </>
            }

        </>

    )
}

export default CreateCustomRule
import React, { useContext, useEffect, useRef, useState } from 'react'
import "./sidebar.css"
import { NavLink, useNavigate } from 'react-router-dom'
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import PushPinIcon from '@mui/icons-material/PushPin';
import SidebarContext from '../../../context/SidebarContext';
import MenuItem from './MenuItem';
import AppReadyContext from '../../../context/AppReadyContext';
import { UNINav } from '../../../axios/apis';
import LogoutIcon from '@mui/icons-material/Logout';
import LogoutButton from '../../logout/LogoutButton';

const Sidebar = ({ hideSidebar, setHideSidebar }) => {

    const { isToggle, isCollapse, setIsToggle, setIsCollapse } = useContext(SidebarContext)
    const [isLogout, setIsLogout] = React.useState(false);
    const [menus, setMenus] = useState([]);
    const [accessToken, setAccessToken] = useState(
        localStorage.getItem("accessToken")
    );
    const { setAppReady } = useContext(AppReadyContext);
    let ref = useRef();
    const navigate = useNavigate();

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            fetchNavigationData(localStorage.getItem("accessToken"));
        }, 100); // Adjust the timeout duration as needed (in milliseconds)

        return () => {
            clearTimeout(timeoutId); // Clear the timeout if the component unmounts or the effect re-runs
        };
    }, [accessToken]);

    const fetchNavigationData = async (accessToken) => {
        try {
            let response = await UNINav();
            localStorage.setItem("Navigation", JSON.stringify(response.data));

            if (localStorage.getItem("userType") === "DrayTalk-DrayTab") {
                const updatedResponse = response.data.map((menuItem) => {
                    if (menuItem.name === "Chat") {
                        return { ...menuItem, path: "/draytab/chat" };
                    } else if (menuItem.name === "Contacts") {
                        return { ...menuItem, path: "/draytab/contacts" };
                    } else {
                        return menuItem;
                    }
                });
                response.data = updatedResponse; // Update the response data with the modified paths
            }
            if (response.data.length > 0) {
                const randomValue = new Date().getTime();
                setAppReady(randomValue);
            } else {
                navigate("/unauthorized");
            }
            const transformedMenus = transformMenuData(response.data);
            setMenus(transformedMenus);
            localStorage.setItem("NewNavBar", transformedMenus)
        } catch (error) {
            console.error("Error fetching menu:", error);
        }
    };

    const transformMenuData = (data) => {
        return data.map((menuItem) => {
            const transformedItem = {
                title: menuItem.name,
                url: menuItem.subMenu ? null : menuItem.path,
                icon: menuItem.icon,
                submenu: null,
            };

            if (menuItem.subMenu && menuItem.subMenu.length > 0) {
                transformedItem.submenu = transformSubMenuData(menuItem.subMenu);
            }

            return transformedItem;
        });
    };

    const transformSubMenuData = (subMenu) => {
        return subMenu.map((subMenuItem) => {
            const transformedSubItem = {
                title: subMenuItem.name,
                url: subMenuItem.path || null,
                submenu: null,
            };

            if (subMenuItem.subMenu && subMenuItem.subMenu.length > 0) {
                transformedSubItem.submenu = transformSubMenuData(subMenuItem.subMenu);
            }

            return transformedSubItem;
        });
    };

    return (
        <div
            onMouseEnter={() => !isCollapse && setIsToggle(true)} onMouseLeave={() => !isCollapse && setIsToggle(false)}
            className={`${isToggle || isCollapse ? "col-w-15 side-bar-left-shadow" : "col-w-6 side-bar-left-shadow"}`}>
            <div className='side-panel'>
                <div className='px-2 py-4 d-flex flex-column justify-content-between h-100'>
                    <div className="min-height-sidebar">
                        {
                            menus.map((item, index) => {
                                const depthLevel = 0;
                                return <MenuItem key={index} data={item} depthLevel={depthLevel} />
                            })
                        }
                    </div>
                    <div>
                        <div style={{ cursor: "pointer" }} className={`${isToggle ? "wrapper__sidebar_hide" : ""} d-flex align-items-center gap-2 p-2 wrapper__sidebar`} onClick={() => setIsCollapse(!isCollapse)}>

                            <PushPinIcon className={`${isCollapse ? "rotate-0" : "rotate-90"}`} />

                            {
                                isToggle && <h6 className='sidebar__heading px-2'>
                                    {
                                        !isCollapse ? "Pin Menu" : "Unpin Menu"
                                    }
                                </h6>
                            }

                        </div>
                        <div onClick={() => setIsLogout(true)} style={{ cursor: "pointer" }} className={`${isToggle ? "wrapper__sidebar_hide" : ""} mt-2 d-flex align-items-center gap-2 p-2 wrapper__sidebar`}>

                            <LogoutIcon />

                            <h6 className='sidebar__heading px-2'>
                                <LogoutButton isLogout={isLogout} />
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Sidebar
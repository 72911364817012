import React, { useContext, useEffect, useState } from 'react'
import { RulesById, UpdateRuleDetails } from '../../axios/apis'
import AccessContext from '../../context/AccessContext'
import PopupLayout from '../UI/Popup/PopupLayout'
import { useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actionCreators } from '../../store'
import CircleLoader from '../UI/CircleLoader'
import { Col } from 'react-bootstrap'
import ThemeBtn from '../UI/ThemeBtn'
import TreeRuleContainer from '../UserManagement/TreeView/TreeRuleContainer'
import { successNotification, unexpectedErrorNotification } from '../../helpers'
import { findPathForNameRule } from '../../utils/helperPages'
import SearchContext from '../../context/SearchContext'
import RequiredAstrict from '../UI/RequiredAstrict'

const UpdateRuleSet = ({ open, onClose, title, btn, data, fetchDetails }) => {

    const [loader, setLoader] = useState(false)
    const { orgzName, selectedDepartments, setSelectedDepartments, selectedUsers, setSelectedUsers } = useContext(AccessContext)
    const { setFindList } = useContext(SearchContext)

    const dispatch = useDispatch();
    const { addNotifications } = bindActionCreators(actionCreators, dispatch);


    const [submitloader, setSubmitLoader] = useState(false)
    const [isConfirm, setIsConfirm] = useState(false)
    const [ruleName, setRuleName] = useState(data.ruleSetName)
    const [isCallingDisable, setIsCallingDisable] = useState(data.isCallingDisabled)


    const handleConfirm = () => {
        if (selectedDepartments.length === 0 && selectedUsers.length === 0) {
            unexpectedErrorNotification("At Least select 1 Group or User", addNotifications);
        } else {
            setIsConfirm(true)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setSubmitLoader(true)
        const payloadRule = {
            ruleSetId: data.ruleSetId,
            ruleSetName: ruleName,
            departmentIdList: selectedDepartments,
            userIdList: selectedUsers,
            isCallingDisabled: isCallingDisable
        }
        try {
            const resRule = await UpdateRuleDetails(payloadRule)
            if (resRule.status === 200) {
                successNotification({
                    message: "Rules Updated Successfully",
                    addNotifications,
                });
                setSubmitLoader(false)
                onClose()
                fetchDetails("")
            }
        } catch (error) {
            unexpectedErrorNotification(error.response?.data || "An error occurred while Updating the Rule", addNotifications);
            setSubmitLoader(false)
        }
    }


    const fetchRuleDetail = async () => {
        setLoader(true)
        try {
            const resRule = await RulesById(data.ruleSetId)
            const departmentIdList = data.departmentIdList;
            const userIdList = data.userIdList;
            setFindList(findPathForNameRule([orgzName.orgChart], [...departmentIdList, ...userIdList]))
            // Merging two ar

            // setDepartList(resRule.data.departmentList)
            // setUserList(resRule.data.userList)
            setLoader(false)
        } catch (error) {
            unexpectedErrorNotification(error.response.data || "Unable to Load Details", addNotifications);
            // console.log(error)
        }
    }

    useEffect(() => {
        const departmentIdList = data.departmentIdList;
        const userIdList = data.userIdList;
        const stringsArray = userIdList.map(number => String(number)); // Using toString() method

        setSelectedUsers(stringsArray)
        setSelectedDepartments(data.departmentIdList)
        setFindList(findPathForNameRule([orgzName.orgChart], [...departmentIdList, ...userIdList]))

        // fetchRuleDetail()
    }, [])


    return (
        <PopupLayout open={open} onClose={onClose} title={title} handleSubmit={handleSubmit}>
            {
                loader ? <CircleLoader /> : <>
                    {
                        isConfirm ? <>
                            <Col lg={12}>

                                <div className="input-box">
                                    <label className='input-label' htmlFor="">Rule Name <RequiredAstrict /></label>
                                    <div className="input-field">
                                        <input type="text" name="userName" value={ruleName} onChange={(e) => setRuleName(e.target.value)} required />
                                    </div>
                                </div>
                            </Col>
                            <div className='d-flex justify-content-end gap-4'>
                                <button className='cancel-btn' onClick={onClose}>Cancel</button>
                                <ThemeBtn name={btn} loader={submitloader} type="submit" />
                            </div>
                        </> : <TreeRuleContainer orgName={orgzName.organizationName} chart={orgzName.orgChart} isCallingDisable={isCallingDisable} setIsCallingDisable={setIsCallingDisable}>

                            <div className="action-btn">
                                <button type="button" onClick={onClose} className="reset-btn">
                                    Close
                                </button>
                                <ThemeBtn name="Submit" type="button" handleClick={handleConfirm} />
                            </div>
                        </TreeRuleContainer>
                    }
                </>
            }

        </PopupLayout>
    )
}

export default UpdateRuleSet
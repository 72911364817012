import React, { createContext, useContext, useState } from "react";

// Create a new context
const AuthContext = createContext();

// Create a custom hook to access the context
export const useAuth = () => {
  return useContext(AuthContext);
};

// Create a provider component
export const AuthProvider = ({ children }) => {
  const [accessToken, setAccessToken] = useState(localStorage.getItem("accessToken") || null);
  const [userDetails, setUserDetails] = useState(JSON.parse(localStorage.getItem("userDetails")) || {});
  const [navigation, setNavigation] = useState(JSON.parse(localStorage.getItem("Navigation")) || {});
  const [userRole, setUserRole] = useState(""); // Set an initial value for user role
  const [userType, setUserType] = useState("");
  const [userName, setUserName] = useState(""); // Set an initial value for user ID
  const [userId, setUserId] = useState(""); // Set an initial value for user ID
  const [organization, setOrganization] = useState("");
  const [callingDisable, setCallingDisable] = useState(false)
  const [ruleId, setRuleId] = useState(0)


  // Define functions to update context values
  const updateAccessToken = (newToken) => {
    setAccessToken(newToken);
    localStorage.setItem("accessToken", newToken);
  };

  const updateUserDetails = (newUserDetails) => {
    setUserDetails(newUserDetails);
    localStorage.setItem("userDetails", JSON.stringify(newUserDetails));
  };

  const updateNavigation = (newNavigation) => {
    setNavigation(newNavigation);
    localStorage.setItem("Navigation", JSON.stringify(newNavigation));
  };

  const updateUserRole = (newUserRole) => {
    setUserRole(newUserRole);
    localStorage.setItem("user-role", newUserRole)
    // You can optionally store user role in local storage here
  };

  const updateUserType = (newUserType) => {
    setUserType(newUserType);
    localStorage.setItem("user-type", newUserType)
    // You can optionally store user type in local storage here
  };

  const updateUserId = (newUserId) => {
    setUserId(newUserId);
    localStorage.setItem("user", newUserId);
    // You can optionally store user ID in local storage here
  };
  const updateUserName = (newUserName) => {
    setUserName(newUserName);
    localStorage.setItem("userName", newUserName);
    // You can optionally store user ID in local storage here
  };

  const updateOrganization = (newOrganization) => {
    setOrganization(newOrganization);
    localStorage.setItem("Organization", newOrganization);
    // You can optionally store user ID in local storage here
  };

  const updateRuleDetails = (calling, rule) => {
    setCallingDisable(calling)
    setRuleId(rule)
    localStorage.setItem("callingDisable", calling)
    localStorage.setItem("ruleId", rule)
  }


  // Create a context value with the state and updater functions
  const contextValue = {
    accessToken,
    userDetails,
    navigation,
    userRole,
    userType,
    userId,
    userName,
    organization,
    callingDisable,
    ruleId,
    updateAccessToken,
    updateUserDetails,
    updateNavigation,
    updateUserRole,
    updateUserType,
    updateUserId,
    updateUserName,
    updateOrganization,
    updateRuleDetails
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

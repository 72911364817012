import React from 'react'
import MenuItem from './MenuItem'

const SubMenuItem = ({ depthLevel, data }) => {

    depthLevel = depthLevel + 1

    return (
        <>
            <div className='p-3'>
                {data.map((menuItem, index) => (
                    <MenuItem key={index} data={menuItem} depthLevel={depthLevel} />
                ))}
            </div>
        </>
    )
}

export default SubMenuItem
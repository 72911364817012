import React, { useEffect, useState, useContext, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { ReactElement } from "react";
import axios from "axios";
import { Box, Spinner } from "@twilio-paste/core";

import Login from "./login/login";
import AppContainer from "./AppContainer";
import { actionCreators, AppState } from "../store";
import { getToken } from "../api";
import {
  MsalProvider,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { PublicClientApplication, InteractionType } from "@azure/msal-browser";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { msalConfig } from "./azure-auth-config";
import { useNavigate } from "react-router-dom";
import TokenValidityChecker from "./login/TokenValidityChecker";
import Header from "./menu/Header/Header";
import Footer from "./footer/footer";
import Unauthorized from "./login/Unauthorized";
import PostLogoutScreen from "./logout/Logout";
import LoginButton from "./login/LoginButton";
import Home from "./Home";
import AccessManagement from "./AccessManagement/AccessManagement";
import "./App.css";
import Contacts from "./contacts/Contacts";
import AppReadyContext from "../context/AppReadyContext";
import UserManagement from "./UserManagement/UserManagement";
import Approval from "./Approval/Approval";
import SideMenu from "./menu/SideBar/SideMenu";
import RulesManagement from "./RulesManagement/RulesManagement";
import ReportContext from "../context/ReportContext";
import AccessContext from "../context/AccessContext";
import RedirectPage from "./login/Redirect";
import jwtDecode from "jwt-decode";
import Sound from "../assets/cute_sound.mp3";
import { ChatRoom } from "../firebase-config";
import VideoApp from "./Video-App/App";
import Toaster from "../components/UI/Toaster";
import ToastNotification from "./UI/ToastNotifications";
import MessageNotification from "./UI/MessageNotification";
import CheckPermissionModal from "./UI/CheckPermissionModal";
import Sidebar from "./menu/SidebarMenu/Sidebar";
import SidebarContext from "../context/SidebarContext";
const msalInstance = new PublicClientApplication(msalConfig);

function App() {
  const soundPlayed = useRef(false);
  const unreadMessages = useSelector((state) => state.unreadMessages);
  const [status, setStatus] = useState("");
  const { reportType } = useContext(ReportContext);
  const { appReady, setAppReady } = useContext(AppReadyContext);
  let userType = "decodedToken.app_displayname";
  if (localStorage.getItem("accessToken")) {
    const decodedToken = jwtDecode(localStorage.getItem("accessToken"));
    userType = decodedToken.app_displayname;
  }


  const { isToggle, isCollapse } = useContext(SidebarContext)

  const dynamicStyle = `
    .menus > .${reportType} {
      background-color: #7194A8;
    }
  `;

  const resetStatus = () => {
    setStatus(""); // Reset the status to an empty string
  };

  function sumNumericValues(obj) {
    let sum = 0;

    for (const key in obj) {
      if (typeof obj[key] === "number" && obj[key] !== 0) {
        sum += obj[key];
      }
    }

    if (sum !== 0) {
      return `DrayTalk (${sum})`;
    } else {
      return "DrayTalk";
    }
  }

  // useEffect(() => {
  //   document.title = sumNumericValues(unreadMessages)
  // }, [unreadMessages])

  useEffect(() => {
    const sumNumericValues = (obj) => {
      let sum = 0;
      for (const key in obj) {
        if (typeof obj[key] === "number" && obj[key] !== 0) {
          sum += obj[key];
        }
      }
      return sum !== 0 ? `(${sum}) DrayTalk` : "DrayTalk";
    };
    document.title = sumNumericValues(unreadMessages);

    // if (!soundPlayed.current && sumNumericValues(unreadMessages) !== "DrayTalk") {
    //   // Play the sound here
    //   const audio = new Audio(Sound);
    //   audio.play();
    //   // soundPlayed.current = true; // Mark the sound as played
    // }
  }, [unreadMessages]);

  return (
    <MsalProvider instance={msalInstance}>
      {/* <firebaseApp/> */}
      {/* <CheckPermissionModal /> */}

      <Router>
        <div className="App">
          <style>{dynamicStyle}</style>
          <ChatRoom />
          <Toaster />
          <ToastNotification />
          <MessageNotification />
          <TokenRefresher />
          <AuthenticatedTemplate>
            {userType != "DrayTalk-DrayTab" && (
              <>
                <Header />

                {/* <button onClick={togglePlay}>{isPlaying ? 'Pause' : 'Play'}</button>
                <audio ref={audioRef} src={Sound} /> */}
                {/* <SideMenu /> */}
                <TokenValidityChecker />
                <div className="d-flex">
                  <Sidebar />
                  <div style={{ height: "calc(100vh - 4rem)", overflow: "scroll" }} className={`${isToggle || isCollapse ? "col-w-94" : "col-w-95"}`}>
                    <div className="w-100">
                      <Routes>
                        <Route path="/" element={<Home key={appReady} />} />
                        <Route path="/chat" element={<Home key={appReady} />} />
                        <Route path="video" element={<VideoApp key={appReady} />} />
                        <Route
                          path="/contacts"
                          element={<Contacts key={appReady} />}
                        />
                        <Route
                          path="/accessmanagement"
                          element={<AccessManagement />}
                        />
                        <Route
                          path="/usermanagement"
                          element={<UserManagement />}
                        />
                        <Route
                          path="/rulesmanagement"
                          element={<RulesManagement />}
                        />
                        <Route path="/requests" element={<Approval />} />
                        <Route path="/unauthorized" element={<Unauthorized />} />
                      </Routes>
                    </div>
                  </div>
                </div>
                <Footer></Footer>
              </>
            )}
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <Routes>
              <Route
                path="/redirect/:correlationId/:notificationId?"
                element={<RedirectPage />}
              />
              <Route path="/logout" element={<PostLogoutScreen />} />
              <Route path="/" element={<LoginButton />} />
              <Route path="/unauthorized" element={<Unauthorized />} />
              <Route path="/draytab/logout" element={<PostLogoutScreen />} />
            </Routes>
          </UnauthenticatedTemplate>
        </div>
      </Router>
      {userType == "DrayTalk-DrayTab" && (
        <>
          {/* <ChatRoom />
          <Toaster />
          <ToastNotification />
          <MessageNotification /> */}
          <Router>
            {localStorage.getItem("accessToken") && (
              <>
                <DraytabTokenRefresher />
                <Header />
              </>
            )}

            <div className="d-flex">
              {localStorage.getItem("accessToken") && (
                <>
                  <SideMenu />
                </>
              )}

              <Routes>
                {/* Custom authenticated routes */}
                <Route
                  path="/custom-auth-route/:correlationId"
                  element={<RedirectPage />}
                />
                <Route path="/draytab/chat" element={<Home key={appReady} />} />
                <Route path="/draytab/contacts" element={<Contacts />} />
                <Route path="/unauthorized" element={<Unauthorized />} />
                <Route path="/draytab/logout" element={<PostLogoutScreen />} />
                {/* Add more custom routes here */}
              </Routes>
            </div>
            <Footer></Footer>
          </Router>
        </>
      )}
    </MsalProvider>
  );
}

const LogoutRedirect = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Perform any necessary cleanup or post-logout actions here

    // Redirect the user to the desired page after logout
    navigate("/"); // Replace with the desired post-logout redirect URL
  }, [navigate]);

  return null;
};

// This component will be responsible for refreshing tokens and handling inactivity
function TokenRefresher() {
  const { instance, accounts, inProgress } = useMsal();
  const navigate = useNavigate();

  useEffect(() => {
    let refreshTimer;
    let inactivityTimer;

    const handleActivity = () => {
      clearTimeout(inactivityTimer);
      if (localStorage.getItem("userType") != "DrayTalk-DrayTab") {
        inactivityTimer = setTimeout(() => {
          if (accounts.length > 0) {
            instance.logout().then(() => {
              navigate("/");
            });
          }
        }, 8 * 60 * 60 * 1000); // <------- 2hr  ,60 * 45 * 1000<----- 45 minutes, 8 * 60 * 60 * 1000 <-----8 hours
      } else {
        inactivityTimer = setTimeout(() => {
          if (accounts.length > 0) {
            instance.logout().then(() => {
              navigate("/");
            });
          }
        }, 8 * 60 * 60 * 1000); // 8 Hours
      }
    };

    const resetInactivityTimer = () => {
      clearTimeout(inactivityTimer);
      inactivityTimer = setTimeout(handleActivity, 60 * 5 * 1000); // 5 minutes
    };

    const refreshToken = () => {
      if (accounts.length > 0 && inProgress === "none") {
        // console.log("Fetching refreshed Token");

        instance
          .acquireTokenSilent({
            account: accounts[0],
            scopes: ["user.read"],
            forceRefresh: true,
          })
          .then((response) => {
            // console.log(response.accessToken);
            localStorage.setItem("RefreshToken: ", response.accessToken);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    };

    handleActivity(); // Start the inactivity timer initially

    // refreshTimer = setInterval(refreshToken, 60 * 60 * 1000);

    window.addEventListener("mousemove", resetInactivityTimer);
    window.addEventListener("keydown", resetInactivityTimer);

    return () => {
      clearInterval(refreshTimer);
      clearTimeout(inactivityTimer);
      window.removeEventListener("mousemove", resetInactivityTimer);
      window.removeEventListener("keydown", resetInactivityTimer);
    };
  }, [accounts, inProgress, instance, navigate]);

  return null;
}

function DraytabTokenRefresher() {
  const navigate = useNavigate();
  const [lastActivityTime, setLastActivityTime] = useState(Date.now());
  const refreshToken = async (correlationId) => {
    try {
      const response = await axios.post(
        "https://draytalk-apim.azure-api.net/api/UserManagement/v1/RefreshUser",
        {
          correlationId: correlationId,
        }
      );
      localStorage.setItem("accessToken", response.data.access_token);
      // console.log("Token refreshed successfully.");
    } catch (error) {
      console.error("Error refreshing token:", error);
    }
  };

  const checkTokenValidity = () => {
    if (localStorage.getItem("userType") === "DrayTalk-DrayTab") {
      const decodedToken = jwtDecode(localStorage.getItem("accessToken"));
      const correlationId = localStorage.getItem("correlationId");
      const currentTime = Math.floor(Date.now() / 1000); // Convert to seconds
      const validityDuration = decodedToken.exp - currentTime;
      // console.log("Token validity duration:", validityDuration);

      if (validityDuration < 600) {
        // Token expires in less than 10 minutes (600 seconds)
        // console.log("Token validity is less than 10 minutes, refreshing...");
        refreshToken(correlationId);
      }
    }

    // Check for user inactivity
    const currentTime = Date.now();
    const inactiveDuration = (currentTime - lastActivityTime) / 1000; // Convert to seconds
    if (inactiveDuration > 8 * 60 * 60) {
      // User has been inactive for more than 15 minutes (900 seconds)
      // console.log("User inactive for more than 15 minutes, logging out...");
      // Call your logout function here
      localStorage.removeItem("accessToken");
      navigate("/draytab/logout");
    }
  };

  // Function to handle user activity
  const handleUserActivity = () => {
    setLastActivityTime(Date.now());
  };

  // Add event listeners for user activity
  useEffect(() => {
    window.addEventListener("mousemove", handleUserActivity);
    window.addEventListener("keydown", handleUserActivity);
    window.addEventListener("touchstart", handleUserActivity);
    window.addEventListener("touchmove", handleUserActivity);

    // Cleanup event listeners on unmount
    return () => {
      window.removeEventListener("mousemove", handleUserActivity);
      window.removeEventListener("keydown", handleUserActivity);
      window.removeEventListener("touchstart", handleUserActivity);
      window.removeEventListener("touchmove", handleUserActivity);
    };
  }, []);

  useEffect(() => {
    const intervalId = setInterval(checkTokenValidity, 300000); // Check every 5 minutes (300000 milliseconds)
    return () => clearInterval(intervalId); // Cleanup on unmount
  }, []);

  // Render nothing because this component doesn't have UI
  return null;
}

export default App;

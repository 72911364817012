import React, { CSSProperties, useState, useEffect, useContext } from "react";
import { Client } from "@twilio/conversations";
import { ChevronDoubleLeftIcon } from "@twilio-paste/icons/esm/ChevronDoubleLeftIcon";
import { Box, Input } from "@twilio-paste/core";
import { ChevronDoubleRightIcon } from "@twilio-paste/icons/esm/ChevronDoubleRightIcon";
import axios from "axios";
import CreateConversationButton from "./CreateConversationButton";
import ConversationsList from "./ConversationsList";
import styles from "../../styles";

import { useDispatch } from "react-redux";
import { filterConversations } from "./../../store/action-creators";
import { MessageContext } from "../../context/MessageContext";

interface ConvosContainerProps {
  client?: Client;
}

const ConversationsContainer: React.FC<ConvosContainerProps> = (
  props: ConvosContainerProps
) => {
  const [listHidden, hideList] = useState(false);
  const dispatch = useDispatch();
  const { message, setMessage, accepted, setAccepted } =
  useContext(MessageContext);
  const handleSearch = (searchString: string) => {
    dispatch(filterConversations(searchString));
  };

  useEffect(() => {
    const fetchNotificationInfo = async () => {
      if (localStorage.getItem("incomingCallId")) {
        try {
          const response = await axios.get(
            `https://draytalk-apim.azure-api.net/FireBaseNotification/get-notification-id?notificationId=${localStorage.getItem(
              "incomingCallId"
            )}`
          );
          response.data.Seen = false;
          response.data.SentBy = response.data?.fromEmail
          response.data.Type="Call";
          console.log("Notification Info: ", JSON.stringify(response.data));
           // Wait for 2 seconds before setting the message
           setTimeout(() => {
            setMessage(response.data);
          }, 2000);
          // localStorage.removeItem("incomingCallId");
        } catch (error) {
          console.error("Error fetching notification info: ", error);
        }
      }
    };

    fetchNotificationInfo();
  }, []);

  return (
    <Box
      className="conversationsList"
      style={
        listHidden
          ? { ...styles.convosWrapper, ...styles.collapsedList }
          : styles.convosWrapper
      }
    >
      {!listHidden && (
        <Box style={styles.newConvoButton}>
          <CreateConversationButton
            client={props.client}
            collapsed={listHidden}
          />
          <Box>
            {!listHidden ? (
              <Input
                aria-describedby="convo_string_search"
                id="convoString"
                name="convoString"
                type="text"
                placeholder="Search"
                onChange={(e) => handleSearch(e.target.value)}
                required
                // autoFocus
              />
            ) : null}
          </Box>
        </Box>
      )}

      <Box style={styles.convoList}>
        {!listHidden ? <ConversationsList /> : null}
      </Box>
      <Box style={styles.collapseButtonBox}>
        <Box
          paddingTop="space30"
          style={{
            paddingLeft: 10,
            paddingRight: 10,
          }}
          onClick={() => hideList(!listHidden)}
        >
          {listHidden ? (
            <ChevronDoubleRightIcon decorative={false} title="Collapse" />
          ) : (
            <ChevronDoubleLeftIcon decorative={false} title="Collapse" />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ConversationsContainer;

import { result } from "lodash"
import timezones from "./timezones.json"
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import SettingsIcon from '@mui/icons-material/Settings';

export const handleIcon = (val) => {
    switch (val) {
        case "chat": return <ChatBubbleOutlineIcon />
        case "contacts": return <PermContactCalendarIcon />
        case "admin": return <SettingsIcon />
    }
}

// handle for the react select option list ->  label and value form
export const labelConvert = (list) => {
    let arr = []
    list.map((e) => {
        arr.push({ label: e, value: e })
    })
    return arr
}

export const labelConvertRules = (list) => {
    let arr = []
    list.map((item) => {
        arr.push({ label: item.ruleSetName, value: item.ruleSetId })
    })
    return arr
}

// Extract all the name of the groups and the user form orgchar -> label and value
export const extractNames = (data, result = []) => {
    for (const item of data) {
        if (item?.userContactList) {
            item.userContactList.forEach(user => {
                if (user.userEmailId && user.userName) {
                    result.push({
                        value: user.userEmailId,
                        label: user.userName
                    });
                }
            });
        }
        if (item?.otherContactList) {
            item.otherContactList.forEach(user => {
                if (user.userEmailId && user.userName) {
                    result.push({
                        value: user.userEmailId,
                        label: user.userName
                    });
                }
            });
        }
        if (item?.children) {
            const childResult = extractNames(item.children);
            result = result.concat(childResult);
        }
        if (item?.department) {
            if (item.department.includes('@')) {
                result.push({ value: item.department, label: item.department });
            } else {
                result.push({ value: null, label: item.department });
            }
        }
    }

    // Sort the result array by userName
    result.sort((a, b) => {
        const nameA = a.label.toUpperCase(); // convert names to uppercase for case-insensitive comparison
        const nameB = b.label.toUpperCase();
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    });

    return result;
};

// Give only department name (parent) - > Label and value
export const extractLevelNames = (data, result = []) => {
    for (const item of data) {
        if (item?.children) {
            const childResult = extractLevelNames(item.children);
            result = result.concat(childResult);
        }
        if (item?.department) {
            if (item.department.includes('@')) {
                result.push({ value: item.parentDepartmentId, label: item.department });
            } else {
                result.push({ value: item.parentDepartmentId, label: item.department });
            }
        }
    }

    // Sort the result array by userName
    result.sort((a, b) => {
        const nameA = a.label.toUpperCase(); // convert names to uppercase for case-insensitive comparison
        const nameB = b.label.toUpperCase();
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    });

    return result;
}

// Give the parent department details 
export const findParentDepartment = (orgChart, departmentId) => {
    if (!orgChart || !departmentId) return null;

    // Extract department names from the org chart
    const departmentNames = extractDepartmentName([orgChart]);

    // Find the department object with the given departmentId
    const department = departmentNames.find(item => item.value === departmentId);
    if (!department) return null; // Department with given departmentId not found

    // Find and return the parent department
    return departmentNames.find(item => item.value === department.parentDepartmentId);
}

// Give only department name - > Label and value
// export const extractDepartmentName = (data, result = []) => {
//     for (const item of data) {
//         if (item?.children) {
//             const childResult = extractDepartmentName(item.children);
//             result = result.concat(childResult);
//         }
//         if (item?.department) {
//             if (item.department.includes('@')) {
//                 result.push({ value: item.departmentId, label: item.department });
//             } else {
//                 result.push({ value: item.departmentId, label: item.department });
//             }
//         }
//     }

//     // Sort the result array by userName
//     result.sort((a, b) => {
//         const nameA = a.label.toUpperCase(); // convert names to uppercase for case-insensitive comparison
//         const nameB = b.label.toUpperCase();
//         if (nameA < nameB) {
//             return -1;
//         }
//         if (nameA > nameB) {
//             return 1;
//         }
//         return 0;
//     });
//     return result;
// }

export const extractDepartmentName = (data, result = [], parentName = '') => {
    for (const item of data) {
        const currentName = parentName ? `${parentName} > ${item.department}` : item.department;

        if (item?.children) {
            const childResult = extractDepartmentName(item.children, [], currentName);
            result = result.concat(childResult);
        }

        if (item?.department) {
            if (item.department.includes('@')) {
                result.push({ value: item.departmentId, label: currentName });
            } else {
                result.push({ value: item.departmentId, label: currentName });
            }
        }
    }

    // Sort the result array by userName
    result.sort((a, b) => {
        const nameA = a.label.toUpperCase(); // convert names to uppercase for case-insensitive comparison
        const nameB = b.label.toUpperCase();
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    });

    return result;
}

export const getDepartmentPath = (data, targetId, path = '') => {
    for (const item of data) {
        // Check if the current item matches the target department ID
        if (item.departmentId === targetId) {
            return path ? `${path} > ${item.department}` : item.department;
        }

        // Recursively check the children
        if (item?.children) {
            const childPath = getDepartmentPath(item.children, targetId, path ? `${path} > ${item.department}` : item.department);
            if (childPath) {
                return childPath;
            }
        }
    }

    // Return null if not found
    return null;
};

export const findDepartmentById = (data, id) => {
    for (const item of data) {
        if (item?.children) {
            const found = findDepartmentById(item.children, id);
            if (found) return found;
        }
        if (item.departmentId === id) {
            return item;
        }
    }
    return null; // Return null if department with specified ID is not found
}


export function getTimezoneName(identifier) {
    for (const tz of timezones) {
        if (tz.utc.includes(identifier)) {
            return tz.value;
        }
    }
    return null; // Return null if the identifier is not found
}

export const findPathForName = (data, valueToFind, currentPath = []) => {
    for (const item of data) {
        if (item.department === valueToFind || (item.userContactList && item.userContactList.some(person => person.userEmailId === valueToFind))) {
            currentPath.push(item.department); // Add the current title to the path
            return currentPath; // Return the path for the title or name found
        }

        if (item.children) {
            const pathInSubGroup = findPathForName(item.children, valueToFind, [...currentPath, item.department]);
            if (pathInSubGroup.length > 0) {
                return pathInSubGroup; // Return the path found in subGroup
            }
        }
    }

    return [];
};

export const findPathForNameRule = (data, valueToFind, currentPath = []) => {
    for (const item of data) {
        if (valueToFind.includes(item.departmentId) ||
            (item.userContactList && item.userContactList.some(person => valueToFind.includes(person.id)))) {
            currentPath.push(item.department); // Add the current title to the path
            return currentPath; // Return the path for the title or name found
        }
        if (item.children) {
            const pathInSubGroup = findPathForNameRule(item.children, valueToFind, [...currentPath, item.department]);
            if (pathInSubGroup.length > 0) {
                return pathInSubGroup; // Return the path found in subGroup
            }
        }
    }
    return [];
};




// handle the dynamic query for the api
export const generateQueryParams = (queryObject) => {
    const params = [];

    for (const key in queryObject) {
        if (queryObject.hasOwnProperty(key)) {
            if (key === "PageNumber" || key === "PageSize" || key === "sortingOrder" || key === "sortingColumn") {
                const value = queryObject[key];
                params.push(`${key}=${value}`);

            } else if (queryObject[key].length > 0) {
                const value = queryObject[key];
                params.push(`${key}=${value}`);
            }
        }
    }

    return params
}

export function generateUniqueValue() {
    var currentDate = new Date();

    // Get individual date components
    var year = currentDate.getFullYear().toString();
    var month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-indexed
    var day = currentDate.getDate().toString().padStart(2, '0');
    var hours = currentDate.getHours().toString().padStart(2, '0');
    var minutes = currentDate.getMinutes().toString().padStart(2, '0');
    var seconds = currentDate.getSeconds().toString().padStart(2, '0');
    var milliseconds = currentDate.getMilliseconds().toString().padStart(3, '0');

    // Concatenate and return a unique value
    var uniqueValue = year + "-" + month + "-" + day + "T" + hours + ":" + minutes + ":" + seconds + ":" + milliseconds;
    return uniqueValue;
}



export const trimErrorMessage = (errorMessage) => {
    // Find the index of ": "
    const index = errorMessage.indexOf(": ") + 2;

    // Return the substring from index onwards
    const trimmedMessage = errorMessage.substring(index);

    return trimmedMessage;
}
import React, { useEffect, useState } from 'react'
import { TableRow, TableCell, Button, Switch } from "@mui/material"
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import ViewRuleModal from '../ViewRuleModal';
import UpdateRuleSet from '../UpdateRuleSet';
import DeleteRuleSet from '../DeleteRuleSet';
import DeleteEmptyRuleSet from '../DeleteEmptyRuleSet';
import ToggleButton from './ToggleButton';
import { UpdateRuleDetails } from '../../../axios/apis';
import { successNotification, unexpectedErrorNotification } from '../../../helpers';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../store';
import { useDispatch } from 'react-redux';


const RulestableRow = ({ data, ruleData, handleSort }) => {

    const { ruleSetName, userIdList, departmentIdList, ruleSetId, isCallingDisabled } = data


    const [isDeleteActive, setIsDeleteActive] = useState(false)
    const [isEditActive, setIsEditActive] = useState(false)
    const [isShow, setIsShow] = useState(false)

    const [debounceCall, setDebouncedCall] = useState(false)

    const dispatch = useDispatch();
    const { addNotifications } = bindActionCreators(actionCreators, dispatch);


    // useEffect(() => {
    //     const timeout = setTimeout(() => {
    //         handleChange();
    //         console.log("Clicked")
    //     }, 500); // Debounce delay

    //     return () => clearTimeout(timeout); // Cleanup on re-render or unmount
    // }, [debounceCall]);

    const handleClick = async () => {
        try {
            const resRule = await UpdateRuleDetails({ ruleSetId: ruleSetId, isCallingDisabled: !isCallingDisabled })
            if (resRule.status === 200) {
                successNotification({
                    message: "Calling Updated Successfully",
                    addNotifications,
                });
                handleSort("")
            }
        } catch (error) {
            console.log()
            unexpectedErrorNotification(error.response?.data || "An error occurred while Updating the Rule", addNotifications);
        }
    }

    return (
        <>
            <TableRow className="tableRow" >
                <TableCell>{ruleSetName || "--"}</TableCell>
                <TableCell>{ruleSetName === "Default" ? "All" : departmentIdList.length || "--"}</TableCell>
                <TableCell>{ruleSetName === "Default" ? "All" : userIdList.length || "--"}</TableCell>
                <TableCell><Switch sx={{
                    '& .MuiSwitch-switchBase.Mui-checked': {
                        color: "#163e55",
                        '&:hover': {
                            // backgroundColor: "#163e55",
                        },
                    },
                    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                        backgroundColor: "#163e55",
                    },
                }} checked={!isCallingDisabled} onClick={handleClick} /></TableCell>
                <TableCell>
                    {
                        ruleSetName !== "Default" && <div className='dot-box'>
                            <VisibilityIcon onClick={() => setIsShow(true)} className='me-3' />
                            <EditIcon onClick={() => setIsEditActive(true)} className='me-3' />
                            <DeleteIcon onClick={() => setIsDeleteActive(true)} className='me-3' />
                        </div>
                    }
                </TableCell>
            </TableRow>
            {
                isShow && <ViewRuleModal open={isShow} onClose={() => setIsShow(false)} title={"Rule Details"} btn={"View"} data={data} />
            }
            {
                isEditActive && <UpdateRuleSet open={isEditActive} onClose={() => setIsEditActive(false)} title={"Update Rule"} btn={"Update"} data={data} fetchDetails={handleSort} />
            }
            {
                isDeleteActive && <DeleteRuleSet open={isDeleteActive} onClose={() => setIsDeleteActive(false)} title={"Delete Rule"} btn={"Delete"} data={data} fetchDetails={handleSort} ruleData={ruleData} />
            }
        </>
    )
}

export default RulestableRow